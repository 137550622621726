import React from "react";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import AddProviders from "../../shared/react/AddProviders.jsx";
import Alert from "../../shared/react/Alert.jsx";
import AboutBitrhythmCard from "./AboutBitRhythmCard.jsx";
import ContactCard from "./ContactCard.jsx";
import ResourcesCard from "./ResourcesCard.jsx";

function SupportPage() {
  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  return (
    <AddProviders>
      <Alert message={error} setMessage={setError} level="error" />
      <ContactCard />
      <ResourcesCard />
      <AboutBitrhythmCard setError={setError} />
    </AddProviders>
  );
}

export default SupportPage;
