import angular from "angular";

import {angularizeDirective} from "../../../shared/react-in-angular.js";
import StudyDetailsWrapper from "./StudyDetailsWrapper.jsx";

export default angular.module("app.dialogs.studyDetails", []).name;

// matches <br-details>
angularizeDirective(StudyDetailsWrapper, "brDetails", angular.module("app.dialogs.studyDetails"), {
  study: "<",
  triage: "<",
  modified: "<",
});
