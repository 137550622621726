/* eslint-env browser */
import React from "react";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import bitrhythmIcon from "../../../img/favicon.png";
import tdmIcon from "../../../img/tdm.svg";
import useEnvironmentVariables from "../hooks/useEnvironmentVariables.jsx";
import ResourcesSection from "./ResourcesSection.jsx";

function ResourcesCard() {
  //---------------------------------------------------------------------------
  // Global variables
  //---------------------------------------------------------------------------
  const {apiUrl} = useEnvironmentVariables();

  //---------------------------------------------------------------------------
  // Data to Display
  //---------------------------------------------------------------------------
  const supportResources = React.useMemo(() => {
    return [
      {
        id: "ifu",
        name: "BitRhythm Instructions For Use",
        link: `${apiUrl}/configuration/supportFile/ifu`,
        img: bitrhythmIcon,
        description: "A helpful resource if you have any questions on how to use BitRhythm.",
      },
      {
        id: "tdm",
        name: "Trident Device Manager",
        link: "https://tdm.tzmedical.com/",
        img: tdmIcon,
        description:
          "A Windows application designed to facilitate management of all Trident cardiac monitors over a USB connection.",
      },
    ];
  }, [apiUrl]);

  return (
    <Card sx={{width: "100%", my: 1}}>
      <CardContent sx={{pb: 0}}>
        <Typography variant="h5" align="center">
          Resources
        </Typography>
        <Grid container spacing="3" align="center" sx={{justifyContent: "space-evenly", p: 2}}>
          {supportResources.map((resource) => (
            <ResourcesSection resource={resource} key={`${resource.name}-${resource.id}`} />
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ResourcesCard;
