/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import ButtonWithLink from "../../shared/react/ButtonWithLink.jsx";
import useEnvironmentVariables from "../hooks/useEnvironmentVariables.jsx";
import SoftwareVersion from "./SoftwareVersion.jsx";

function AboutBitrhythmCard({
  // Props
  setError,
}) {
  //---------------------------------------------------------------------------
  // Global variables
  //---------------------------------------------------------------------------
  const {apiUrl} = useEnvironmentVariables();

  return (
    <Card sx={{width: "100%", my: 1}}>
      <CardContent>
        <Typography variant="h5" align="center">
          About BitRhythm
        </Typography>
        <Grid container spacing={6} sx={{justifyContent: "space-evenly", alignItems: "center"}}>
          <Grid size={6} align="right">
            <SoftwareVersion setError={setError} />
          </Grid>
          <Grid size={6} align="left">
            <Typography variant="button" sx={{padding: "14px"}}>
              <ButtonWithLink
                color="secondary"
                variant="text"
                link={`${apiUrl}/configuration/supportFile/softwareReleaseNotes`}
              >
                Software Release Notes
              </ButtonWithLink>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
AboutBitrhythmCard.propTypes = {
  setError: PropTypes.func.isRequired,
};

export default AboutBitrhythmCard;
