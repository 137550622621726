/* eslint-env browser */

import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Launch from "@mui/icons-material/Launch";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import IconButtonWithTooltip from "../../shared/react/IconButtonWithTooltip.jsx";

function StudyDetailsItem({
  // Props
  icon: Icon,
  prefix,
  suffix,
  value,
  handleCopyToClipboard,
  handleOpenInNewTab,
  pageToOpen,
  children,
  warning = "",
  "data-cy": dataCy,
}) {
  return (
    <ListItem disablePadding alignItems="flex-start" data-cy={dataCy}>
      {handleCopyToClipboard && (
        <>
          <Tooltip title="Click to copy" placement="top">
            <ListItemButton
              onClick={handleCopyToClipboard}
              disableGutters
              sx={{p: 0}}
              alignItems="flex-start"
            >
              <ListItemIcon sx={{minWidth: 30, m: 0, fontSize: "small"}}>
                <Icon fontSize="small" color="tertiary" />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{color: "tertiary"}} sx={{my: 0.125}}>
                {prefix}
                {value}
                {suffix}
              </ListItemText>
            </ListItemButton>
          </Tooltip>

          {handleOpenInNewTab && (
            <IconButtonWithTooltip
              title="Open in new tab"
              color="secondary"
              onClick={handleOpenInNewTab}
              otherProps={{href: `/${pageToOpen}`, target: "_blank", size: "small", sx: {p: 0}}}
            >
              <Launch fontSize="small" />
            </IconButtonWithTooltip>
          )}

          {children}
        </>
      )}

      {!handleCopyToClipboard && handleOpenInNewTab && (
        <ListItemButton
          component="a"
          href={`/${pageToOpen}`}
          target="_blank"
          onClick={handleOpenInNewTab}
          disableGutters
          sx={{p: 0}}
          alignItems="flex-start"
        >
          <ListItemIcon sx={{minWidth: 30, m: 0}}>
            <Icon fontSize="small" color="secondary" />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{color: "secondary"}} sx={{my: 0.125}}>
            {prefix}
            {value}
            {suffix}
          </ListItemText>
          <Tooltip title="Open in new tab">
            <Launch fontSize="small" color="secondary" />
          </Tooltip>

          {children}
        </ListItemButton>
      )}

      {!handleCopyToClipboard && !handleOpenInNewTab && (
        <>
          <ListItemIcon sx={{minWidth: 30, m: 0}}>
            <Icon fontSize="small" color="tertiary" />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{color: "tertiary"}} sx={{my: 0.125}}>
            {prefix}
            {value}
            {suffix}
          </ListItemText>
          {children}
          {warning && (
            <Tooltip title={warning}>
              <WarningAmberIcon color="warning" sx={{pl: 1}} data-cy={`${dataCy}-warning`} />
            </Tooltip>
          )}
        </>
      )}
    </ListItem>
  );
}

StudyDetailsItem.propTypes = {
  icon: PropTypes.object.isRequired,
  value: PropTypes.node.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  handleCopyToClipboard: PropTypes.func,
  handleOpenInNewTab: PropTypes.func,
  pageToOpen: PropTypes.string,
  children: PropTypes.node,
  warning: PropTypes.string,
  "data-cy": PropTypes.string,
};

export default React.memo(StudyDetailsItem);
