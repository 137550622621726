import angular from "angular";

import {angularizeDirective} from "../../shared/react-in-angular.js";
import Studies from "./Studies.jsx";

export default angular.module("app.components.studiesTable", []).name;

// matches <inbox-studies-table>
angularizeDirective(Studies, "inboxStudiesTable", angular.module("app.components.studiesTable"), {
  search: "<",
});
