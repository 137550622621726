import angular from "angular";

import {angularizeDirective} from "../../shared/react-in-angular.js";
import SideBarChipWrapper from "./SideBarChipWrapper.jsx";

export default angular.module("app.components.sideBarChip", []).name;

// matches <app-side-bar-chip>
angularizeDirective(SideBarChipWrapper, "appSideBarChip", angular.module("app.components.sideBarChip"), {
  color: "@",
  url: "@",
  params: "<",
  tooltip: "@",
  chipId: "@",
  clickSearch: "@",
  clickPage: "@",
});
