/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import AddProviders from "../../../shared/react/AddProviders.jsx";
import StudyDetails from "./StudyDetails.jsx";

function StudyDetailsWrapper({
  // Props
  study,
  triage = false,
  modified,
}) {
  //---------------------------------------------------------------------------
  // The following variables are the prop names that _should be_ if we weren't
  // using pug templates to connect this to angularjs.
  //---------------------------------------------------------------------------
  const isTriageItem = triage;
  const lastModifiedByUser = modified;

  //---------------------------------------------------------------------------
  // NOTE: The only reason that this file is separate from StudyDetailsDialog.jsx is because of Angular.
  //  Once we switch completely from Angular to React, ConfirmProvider can be moved to an app.js so
  //  that it encapsulates the entire application. Then these two files can be combined.
  //---------------------------------------------------------------------------
  return (
    <AddProviders>
      <StudyDetails study={study} isTriageItem={isTriageItem} lastModifiedByUser={lastModifiedByUser} />
    </AddProviders>
  );
}

StudyDetailsWrapper.propTypes = {
  study: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired,
  triage: PropTypes.bool,
  modified: PropTypes.string,
};

export default StudyDetailsWrapper;
